.fieldset-group .collapser .ion {
  font-size: 150%;
  transition: background-color 0.5s, transform 0.5s;
}

.fieldset-group .collapser {
  position: absolute;
  right: 20px;
  top: -15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.fieldset-group .collapser:hover {
  background-color: #eee;
}

.isDefault-td {
  padding-right: 20px !important;
}
.helper-variables-div {
  padding-bottom: 12px;
}

.helper-variables-div span {
  margin-right: 4px;
}

.fieldset-group-label {
  top: -11px;
  position: relative;
  background-color: #fff;
  margin-bottom: 0;
  padding: 0 5px;
  margin-left: 15px;
  font-size: 115%;
}

.fieldset-group-label-lg {
  font-size: 150%;
  top: -17px;
}

.separator {
  border-bottom: 1px solid #ebebeb;
}

.or-separator {
  position: relative;
  text-align: center;
}

.or-separator:before,
.or-separator:after {
  content: "";
  position: absolute;
  width: calc(50% - 24px);
  height: 1px;
  background-color: #b3bac7;
  top: 8px;
}

.or-separator:before {
  left: 0;
}

.or-separator:after {
  right: 0;
}

.text-center-1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdw-editor-main {
  border: 1px solid #dee2e6 !important;
  border-radius: 4px !important;
  min-height: 130px !important;
}

.border-12 {
  border: 1px solid #dfe1e6 !important;
  border-radius: 8px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Sarabun", sans-serif;
  font-weight: 600;
  color: #6c757d;
}

.position {
  position: fixed;
  top: 75px;
}

.selectDropDown {
  width: 100%;
}

.font-size {
  font-size: 26px;
  color: #aaa;
}

.stepCount {
  /* border: 2px solid #222; */
  width: 32px;
  height: 32px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  /* display: flex; */
  margin-right: 15px;
}

.position .back-button {
  font-size: 36px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: fixed;
  transition: background-color 0.5s, border-radius 0.5s;
}

.page-background {
  background-color: #f8f8fa;
}

.margin-bottom {
  margin-bottom: 70px !important;
}



.table > :not(caption) > * > * {
  /* padding: 0.5rem 0.5rem; */
  /* color: var(--bs-table-color-state,var(--bs-table-color-type,var(--bs-table-color))); */
  /* background-color: var(--bs-table-bg); */
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px
    var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: white !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: rgb(0, 136, 0) !important;
}

.text-sm-start {
  float: right;
}

/* .......extra css........... */
.page-item .one {
  z-index: 3;
  color: #fff;
  background-color: #626ed4;
  border-color: #626ed4;
}

.col-8 {
  flex: 0 0 66.66667% !important;
  max-width: 66.66667% !important;
}

#customerName-field {
  width: 100%;
}

#category-description {
  width: 100%;
}

.fieldset {
  margin-bottom: 0.5rem;
  align-items: center;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 98%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.5rem;
  outline: 0;
}

.fieldset-label {
  white-space: nowrap;
}

.text-right {
  text-align: right !important;
}

.page-background {
  background-color: #f8f8fa;
}

.margin-bottom {
  margin-bottom: 70px !important;
}

.user-role-select.css-b62m3t-container {
  width: 100% !important;
}


.table > :not(caption) > * > * {
  /* padding: 0.5rem 0.5rem; */
  /* color: var(--bs-table-color-state,var(--bs-table-color-type,var(--bs-table-color))); */

  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px
    var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: white !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: rgb(0, 136, 0) !important;
}

.badge-success {
  color: #fff;
  background-color: #02a499;
}

.badge-danger {
  color: #fff;
  background-color: #ec4561;
}

.float {
  float: right !important;
}

/* .........new......... */
.tab-content > .active {
  display: block;
}

.select-service-table td,
.select-service-table th {
  padding: 6px 12px;
}

.select-service-table .check {
  font-size: 135%;
  margin-right: 0.5em;
}

.select-service-table .check_tick {
  color: #01726a !important;
}

input.input-text:read-only {
  background: #eee;
}

.input-text,
.react-date-picker__wrapper {
  align-items: center;
  background-color: white;
  border-color: #cccccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
  outline: 0 !important;
  padding: 2px 10px;
  width: 100%;
  color: #333333;
}

.fieldset {
  margin-bottom: 0.5rem;
  align-items: center;
}

.text-right {
  text-align: right !important;
}

label {
  font-weight: 500;
}

.steps {
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  padding: 12px 20px;
  pointer-events: none;
}

.steps .steps-list {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.steps .steps-list > li {
  flex: 1 1;
  padding-right: 40px;
  margin-left: -46px;
}

.steps .steps-list > li .step {
  display: flex;
  align-items: center;
  color: #222;
  font-weight: bold;
  cursor: pointer;
}

.steps .steps-list > li .step .stepCount {
  border: 2px solid #222;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 15px;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.tab-content > .active {
  display: block;
}

.p-3 {
  padding: 1rem !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}

.border-right-separator {
  border-right: 1px solid #ebebeb;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Sarabun", sans-serif;
  font-weight: 600;
}

h4,
.h4 {
  font-size: 1.3125rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.separator {
  border-bottom: 1px solid #ebebeb;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

table {
  border-collapse: collapse;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8f9fa;
}

.table tbody tr {
  font-size: 110%;
}

.select-service-table td,
.select-service-table th {
  padding: 6px 12px;
}

.table th,
.table td {
  /* padding: 0px 14px; */
  vertical-align: middle;
  border-top: 1px solid #e9ecef;
}

.select-service-table tbody td > .select-row:last-of-type {
  margin-bottom: 0;
}

.select-service-table tbody td > div:first-of-type {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.select-service-table tbody td > div:first-of-type span {
  font-size: 110%;
}

.check {
  font-size: 135%;
  margin-right: 0.5em;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5b626b;
  text-align: left;
  background-color: #f8f8fa;
}

.badge-secondary {
  color: #212529;
  background-color: #e9ecef;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.fieldset-group {
  border: 1px solid #cccccc;
  border-radius: 4px;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  padding: 0 10px 10px;
  position: relative;
}

.email-template-content {
  margin-top: calc(25px + 21px);
  padding: 68px 0;
}

.email-template-content .table-padding {
  margin-top: 80px;
}

.email_templates_font td {
  font-size: 12px;
  padding: 6px;
  vertical-align: middle;
}

@media (min-width: 320px) and (max-width: 767px) {
  .email-template-content {
    margin-top: calc(25px + 21px);
    padding: 40px 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .email-template-content {
    margin-top: calc(25px + 21px);
    padding: 40px 0;
  }
}

@media only screen and (max-width: 1040px) {
  .email-template-content {
    margin-top: calc(25px + 21px);
    padding: 36px 0;
  }
}

.new-item-page-container {
  /* display: flex !important; */
  /* min-height: 100vh !important;
  padding-top: 40px !important;
  padding-bottom: 40px !important; */
}

.container-fluid {
  width: 100% !important;
  padding-right: 12px !important;
  padding-left: 12px !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.new-item-page-nav {
  width: 64px;
}

.new-item-page-container .position {
  font-size: 36px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: fixed;
  transition: background-color 0.5s, border-radius 0.5s;
}
