.alert-success {
  color: #015550;
  background-color: #ccedeb;
  border-color: #b8e6e2;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.Drop-down-width {
  width: 100% !important;
}

@media only screen and (max-width: 992px) {
  .accountbg {
    display: none !important;
  }
}

.alert-danger {
  color: #7b2432;
  background-color: #fbdadf;
  border-color: #facbd3;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}

